import { EntityStateProfile, Profile } from '../store/profile.entity';
import { Injectable } from '@angular/core';
import { selectCurrentProfile, selectCurrentProfileId } from '../store/profile.selector';
import { Store } from '@ngrx/store';

@Injectable()
export class ProfileSelectService {
    private currentProfileId = 0;
    private currentProfile: Profile = null;

    constructor(private store: Store<EntityStateProfile>) {
        store.select(selectCurrentProfileId).subscribe(id => {
            if (id) {
                this.currentProfileId = Number(id);
            }
        });

        store.select(selectCurrentProfile).subscribe((profile: Profile) => this.currentProfile = profile);
    }

    public getCurrentProfileId(): number {
        return this.currentProfileId;
    }

    public getCurrentProfile(): Profile {
        return this.currentProfile;
    }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Profile } from '../../modules/profile/store/profile.entity';
import { Conference } from '../../modules/conference-v2/store/conference.model';
import { MultiUserAppointment } from '../../modules/appointment/store/appointment/multi-user-appointment.entity';
import { Router } from '@angular/router';
import { VONAGE } from '../../routes';
import { AppState } from '../../modules/auth/store/login/login.state';
import { Subject, combineLatest, first, map, Observable } from 'rxjs';
import { TurnConfigService } from '../turn-config/turn-config.service';
import { VonageBrandingSettingsService } from '../vonage-branding-settings/vonage-branding-settings.service';
import { ParticipantType, PatientusVideoConfig } from '@patientus/video-lib-angular/types';
import { getOtherParticipants, getParticipant, getPatientusVideoConfig } from './vonage-configuration';

@Injectable({
    providedIn: 'root'
})
export class VonageConfigurationService {
    config$: Observable<PatientusVideoConfig>;
    loading$: Observable<boolean>;
    appointmentId: number;
    private loadingSubject$: Subject<boolean> = new Subject();

    constructor(private router: Router,
                private store: Store<AppState>,
                private turnConfig: TurnConfigService,
                private videoLibSettings: VonageBrandingSettingsService) {
        this.loadingSubject$.next(false);
        this.loading$ = this.loadingSubject$.asObservable();
    }

    start(conference: Conference, appointment: MultiUserAppointment, profile: Profile, otherProfiles: Profile[]) {
        this.appointmentId = appointment.id;
        this.loadingSubject$.next(true);
        const participant = getParticipant(profile);
        const isDoctor = participant.type === ParticipantType.DOCTOR;
        const otherParticipants = getOtherParticipants(otherProfiles, isDoctor);
        const jwtSelect$ = this.store.select(appStore => appStore.login.jwt);
        const turnConfig$ = this.turnConfig.iceServers$;
        const ipProxy$ = this.videoLibSettings.ipProxy$;
        const logoUrl$ = this.videoLibSettings.logoUrl$;
        const hasNetworkTest$ = this.videoLibSettings.hasNetworkTest$;

        this.config$ = combineLatest([jwtSelect$, turnConfig$, ipProxy$, logoUrl$, hasNetworkTest$])
            .pipe(
                map(([jwt, turnConfig, ipProxy, logoUrl, hasNetworkTest]) => (
                    getPatientusVideoConfig(conference, jwt, ipProxy, turnConfig, logoUrl, appointment, participant, otherParticipants, hasNetworkTest)
                ))
            );

        this.config$
            .pipe(first())
            .subscribe(async() => {
                await this.router.navigate([`/${VONAGE}`]);
                this.loadingSubject$.next(false);
            });
    }


}

import { Conference } from '../../modules/conference-v2/store/conference.model';
import { environment } from '../../../environments/environment';
import { getProfileType, Profile } from '../../modules/profile/store/profile.entity';
import {
    DoctorSpeciality,
    Participant,
    ParticipantGender,
    ParticipantType,
    PatientusVideoConfig
} from '@patientus/video-lib-angular/types';
import { MultiUserAppointment } from '../../modules/appointment/store/appointment/multi-user-appointment.entity';

export const DEFAULT_IP_PROXY = 'https://ipp01.patientus.de';

const sessionApiEndpoint = (conference: Conference): string => `${environment.ovsSessionEndpoint}/${conference?.id}`;

export const getPatientusVideoConfig = (
    conference: Conference,
    jwt: string,
    ipProxy: string,
    turnConfig: RTCIceServer[],
    logoUrl: string,
    appointment: MultiUserAppointment,
    participant: Participant,
    otherParticipants: Participant[],
    networkTest?: boolean,
): PatientusVideoConfig => {
    const config: PatientusVideoConfig = {
        sessionApiEndpoint: sessionApiEndpoint(conference),
        jwt,
        ipProxy,
        turnConfig,
        logoUrl,
        feature: {
            chat: 'embedded'
        },
        appointment: {
            startDateTime: appointment.startDateTime,
            endDateTime: appointment.endDateTime,
            topic: appointment.topic,
            participant,
            otherParticipants,
        },
    };
    if (typeof networkTest === 'boolean') {
        config.feature.networkTest = networkTest;
    }
    return config;
};

export const getParticipant = (profile: Profile, hasInviteUrl?: boolean): Participant => {
    const participant: Participant = {
        id: profile.id,
        title: profile.title,
        firstName: profile.firstName,
        lastName: profile.lastName,
        gender: <ParticipantGender>profile.salutation,
        email: profile.email,
        type: <ParticipantType>getProfileType(profile.type),
        imageUrl: profile.imageUrl,
        specialities: <DoctorSpeciality[]>profile.specialities
    };

    if (hasInviteUrl && profile.tan) {
        participant.inviteUrl = `${window.location.origin}/tan-login?tan=${profile.tan}`;
    }

    if (participant.type === ParticipantType.PATIENT) {
        participant.specialities = <DoctorSpeciality[]>profile.specialities;
    }

    return participant;
}

export const getOtherParticipants = (profiles: Profile[], hasInviteUrl?: boolean): Participant[] => {
    return profiles.map(profile => getParticipant(profile, hasInviteUrl));
}

import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BrandingService } from '../../modules/branding/providers/branding.service';
import { TurnConfig } from '../turn-config/turn-config';
import { VonageBrandingSettings } from './vonage-branding-settings';
import { DEFAULT_IP_PROXY } from '../vonage-configuration/vonage-configuration';

@Injectable({
    providedIn: 'root'
})
export class VonageBrandingSettingsService {

    constructor(private brandingService: BrandingService) {
    }

    get settings$(): Observable<VonageBrandingSettings> {
        return this.brandingService.getBrandingSettingsFromStore().pipe(
            map(brandingState => brandingState.vonageSettings)
        );
    }

    get logoUrl$(): Observable<string> {
        return this.brandingService.getBrandingSettingsFromStore().pipe(
            map(brandingState => brandingState.vonageSettings?.logoUrl || brandingState.ovsLogoUrl || brandingState.smallLogoUrl || '')
        );
    }

    get turnConfig$(): Observable<TurnConfig> {
        return this.settings$.pipe(
            map(settings => settings?.turnConfig)
        );
    }

    get ipProxy$(): Observable<string> {
        return this.settings$.pipe(
            map(settings => settings?.ipProxy ?? DEFAULT_IP_PROXY)
        );
    }

    get hasNetworkTest$(): Observable<boolean> {
        return this.settings$.pipe(
            map(settings => settings?.hasNetworkTest)
        );
    }
}

import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ProfileSelectService } from '../../modules/profile/providers/profile-select.service';
import { ProfileType } from '../../modules/profile/store/profile.entity';
import { TURN_SERVERS } from './turn-config';
import { VonageBrandingSettingsService } from '../vonage-branding-settings/vonage-branding-settings.service';

@Injectable({
    providedIn: 'root'
})
export class TurnConfigService {

    protected configuration: RTCIceServer[] = TURN_SERVERS.default;

    constructor(private videoLibSettings: VonageBrandingSettingsService,
                private profileSelectService: ProfileSelectService) {
    }

    get defaultTurnServers(): RTCIceServer[] {
        return this.configuration;
    }

    get iceServers$(): Observable<RTCIceServer[]> {
        return this.videoLibSettings.turnConfig$.pipe(
            map(turnConfig => {
                if (turnConfig) {
                    const profile = this.profileSelectService.getCurrentProfile();
                    const isPatient = profile?.type === ProfileType.PATIENT;

                    if (isPatient && turnConfig.useDefaultTurnServersForPatients) {
                        return this.defaultTurnServers;
                    }
                    return turnConfig.turnServers;
                }
                return this.defaultTurnServers;
            })
        );
    }
}

var ParticipantType;
(function (ParticipantType) {
  ParticipantType["DOCTOR"] = "doctor";
  ParticipantType["PATIENT"] = "patient";
})(ParticipantType || (ParticipantType = {}));
var ParticipantGender;
(function (ParticipantGender) {
  ParticipantGender["DIVERSE"] = "d";
  ParticipantGender["FEMALE"] = "f";
  ParticipantGender["MALE"] = "m";
})(ParticipantGender || (ParticipantGender = {}));
var ConnectionStatus;
(function (ConnectionStatus) {
  ConnectionStatus["CONNECTING"] = "connecting";
  ConnectionStatus["CONNECTED"] = "connected";
  ConnectionStatus["DISCONNECTED"] = "disconnected";
})(ConnectionStatus || (ConnectionStatus = {}));
var DisconnectionReason;
(function (DisconnectionReason) {
  DisconnectionReason["CLIENT_DISCONNECTED"] = "clientDisconnected";
})(DisconnectionReason || (DisconnectionReason = {}));
var StreamEvent;
(function (StreamEvent) {
  StreamEvent["STREAM_PROPERTY_CHANGED"] = "streamPropertyChanged";
})(StreamEvent || (StreamEvent = {}));
var Language;
(function (Language) {
  Language["CZECH"] = "cs-CZ";
  Language["GERMAN"] = "de-DE";
  Language["ENGLISH"] = "en-US";
  Language["SPANISH_ARGENTINA"] = "es-AR";
  Language["SPANISH_CHILE"] = "es-CL";
  Language["SPANISH_COLOMBIA"] = "es-CO";
  Language["SPANISH_SPAIN"] = "es-ES";
  Language["SPANISH_MEXICO"] = "es-MX";
  Language["SPANISH_PERU"] = "es-PE";
  Language["ITALIAN"] = "it-IT";
  Language["POLISH"] = "pl-PL";
  Language["PORTUGUESE_BRAZIL"] = "pt-BR";
  Language["PORTUGUESE_PORTUGAL"] = "pt-PT";
  Language["TURKISH"] = "tr-TR";
})(Language || (Language = {}));
const LanguageName = {
  [Language.CZECH]: 'Čeština',
  [Language.GERMAN]: 'Deutsch',
  [Language.ENGLISH]: 'English',
  [Language.ITALIAN]: 'Italiano',
  [Language.POLISH]: 'Polski',
  [Language.PORTUGUESE_BRAZIL]: 'Português (do Brasil)',
  [Language.PORTUGUESE_PORTUGAL]: 'Português (Europeu)',
  [Language.SPANISH_ARGENTINA]: 'Español (de Argentina)',
  [Language.SPANISH_CHILE]: 'Español (de Chile)',
  [Language.SPANISH_COLOMBIA]: 'Español (de Colombia)',
  [Language.SPANISH_SPAIN]: 'Español (de España)',
  [Language.SPANISH_MEXICO]: 'Español (de México)',
  [Language.SPANISH_PERU]: 'Español (de Perú)',
  [Language.TURKISH]: 'Türkçe'
};
var SidenavContext;
(function (SidenavContext) {
  SidenavContext["NOTES"] = "notes";
  SidenavContext["FILE_TRANSFER"] = "file-transfer";
  SidenavContext["CHAT"] = "chat";
})(SidenavContext || (SidenavContext = {}));

/**
 * Generated bundle index. Do not edit.
 */

export { ConnectionStatus, DisconnectionReason, Language, LanguageName, ParticipantGender, ParticipantType, SidenavContext, StreamEvent };
